import * as yup from 'yup'
import Messages from './messages'

const MIN_PASSWORD_LENGTH = 8
const MAX_PASSWORD_LENGTH = 128

yup.addMethod(yup.mixed, 'sameAs', function (ref, message) {
  return this.test('sameAs', message, function (value) {
    const other = this.resolve(ref)
    return !other || !value || value === other
  })
})

yup.addMethod(yup.string, 'notAllowedOutEastDomain', function (message) {
  return this.matches(/.*?@(?!(?:outeast)\.com$).*?$/, { message })
})

export const signInUserSchema = yup.object({
  email: yup.string()
    .email(Messages.email.valid)
    .required(Messages.email.required)
    .notAllowedOutEastDomain(Messages.email.notAllowedOutEastDomain),
  password: yup.string()
    .required(Messages.password.required)
})

export const signUpUserSchema = yup.object({
  email: yup.string()
    .email(Messages.email.valid)
    .required(Messages.email.required)
    .notAllowedOutEastDomain(Messages.email.notAllowedOutEastDomain),
  password: yup.string()
    .required(Messages.password.required)
    .min(MIN_PASSWORD_LENGTH, Messages.password.min)
    .max(MAX_PASSWORD_LENGTH)
})

export const authResetUserSchema = yup.object({
  email: yup.string()
    .email(Messages.email.valid)
    .required(Messages.email.required)
})

export const userInfoSchema = yup.object({
  name: yup.string().nullable(),
  email: yup.string().nullable()
    .email(Messages.email.valid)
    .required(Messages.email.required)
    .notAllowedOutEastDomain(Messages.email.notAllowedOutEastDomain),
  phone: yup.string().nullable()
})


export const editPasswordSchema = yup.object({
  currentPassword: yup.string().nullable()
    .required(Messages.currentPassword.required),
  password: yup.string().nullable()
    .required(Messages.password.required)
    .min(MIN_PASSWORD_LENGTH, Messages.password.min)
    .max(MAX_PASSWORD_LENGTH),
  passwordConfirmation: yup.string().nullable()
    .sameAs(yup.ref('password'), Messages.passwordConfirmation.sameAs)
    .required(Messages.passwordConfirmation.required)
})

export const resetPasswordSchema = yup.object({
  password: yup.string()
    .required(Messages.password.requiredNew),
  passwordConfirmation: yup.string()
    .sameAs(yup.ref('password'), Messages.passwordConfirmation.sameAs)
    .required(Messages.passwordConfirmation.required)
})

export const setPasswordSchema = yup.object({
  password: yup.string()
    .required(Messages.password.requiredNew),
  passwordConfirmation: yup.string()
    .sameAs(yup.ref('password'), Messages.passwordConfirmation.sameAs)
    .required(Messages.passwordConfirmation.required)
})
