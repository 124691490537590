import { lens, multi } from 'lorgnette'
import { observable, action, computed, reaction, makeObservable, toJS } from 'mobx'
import { isObject } from '../../../lib/utils/common'

class PhotoGalleryUIStateStore {
  @observable listingId = null
  @observable contentType = ''
  @observable isLightBoxActive = false
  @observable isMapActive = false
  @observable currentImageIndex = 0
  @observable isVideoTourModalOpen = false
  @observable isMapModalOpen = false
  @observable videoTour = ''
  @observable imageUrls = []
  @observable images = []
  variant = null
  previewVariant = null

  @observable interestingPlacesFilters = {
    recreation: true,
    culture: true,
    transportation: true,
    beach: true,
    fitness: true,
    farmAndFood: true
  }

  setInitialSlide = null

  constructor({ sessionStorage, gaEvents }) {
    makeObservable(this)

    this.sessionStorage = sessionStorage
    this.gaEvents = gaEvents

    this.setObservers()
  }

  @action('[PhotoGalleryUIStateStore] set initial data')
  setInitialData({
    listingId,
    images,
    variant = 'url',
    previewVariant = 'thumb',
    videoTour
  }) {
    this.images = images
    this.listingId = listingId
    this.variant = variant
    this.previewVariant = previewVariant
    this.currentImageIndex = 0
    this.videoTour = videoTour

    this.imageUrls = this.buildImageUrls(images)
  }

  setObservers() {
    reaction(() => this.contentType, contentType => {
      if (contentType === 'map') {
        this.gaEvents.Hdp.photoGalleryMapOpen()
      }
    })

    reaction(() => [this.isLightBoxActive, this.currentImageIndex], ([isLightBoxActive]) => {
      if (isLightBoxActive) {
        this.updateImageUrls()
        this.checkMapActive()
      }
    })
  }

  @computed
  get nextImageIndex() {
    return (this.currentImageIndex + this.images.length + 1) % this.images.length
  }

  @computed
  get prevImageIndex() {
    return (this.currentImageIndex + this.images.length - 1) % this.images.length
  }

  @action('[PhotoGalleryUIStateStore] update data')
  updateInfo({ photos, id, variant = 'url', previewVariant = 'thumb' }) {
    this.images = photos
    this.listingId = id
    this.currentImageIndex = 0
    this.variant = variant
    this.previewVariant = previewVariant
  }

  @action('[PhotoGalleryUIStateStore] update image urls')
  updateImageUrls() {
    if (this.currentImageIndex === this.images.length) { return }

    const indexes = [this.prevImageIndex, this.currentImageIndex, this.nextImageIndex]
    const newUrlsLens = multi(...indexes.map(index => lens.at(index)))
    const newValues = indexes.map(index => this.images[index][this.variant])

    this.imageUrls = newUrlsLens.set(this.imageUrls, ...newValues)
  }

  @action('[PhotoGalleryUIStateStore] check map active')
  checkMapActive() {
    if (this.currentImageIndex === this.images.length) {
      this.isMapActive = true
    }
  }

  @action('[PhotoGalleryUIStateStore] replace image url')
  replaceImageUrl(index, imageUrl) {
    const imageItem = toJS(this.images[index])
    const formats = ['webp', 'jpeg']

    for (const variant in imageItem) {
      if (!isObject(imageItem[variant])) {
        continue
      }

      formats.forEach( format => {
        if (format in imageItem[variant]) {
          imageItem[variant][format] = imageUrl
        }
      })
    }

    this.images[index] = imageItem
    this.imageUrls = this.buildImageUrls(this.images)
  }

  @action('[PhotoGalleryUIStateStore] Reset')
  reset = () => {
    this.currentImageIndex = 0
  }

  @action('[PhotoGalleryUIStateStore] Change contentType')
  changeContentType = v => {
    this.contentType = v
  }

  @action('[PhotoGalleryUIStateStore] Toggle isLightBoxActive')
  toggleLightBoxActive = v => {
    this.isLightBoxActive = v
  }

  @action('[PhotoGalleryUIStateStore] Update currentImageIndex')
  updateCurrentImage = v => {
    this.currentImageIndex = v
  }

  @action('[PhotoGalleryUIStateStore] update video tour')
  updateVideoTour(videoTour) {
    this.videoTour = videoTour
  }

  @action('[PhotoGalleryUIStateStore] Toggle isVideoTourModalOpen')
  toggleVideoTourModal = v => {
    this.isVideoTourModalOpen = v
  }

  @action('[PhotoGalleryUIStateStore] Toggle isMapModalOpen')
  toggleMapModal = v => {
    this.isMapModalOpen = v
  }

  buildImageUrls = images => images.map(image => image[this.previewVariant])
}

export default PhotoGalleryUIStateStore
