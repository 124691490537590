import { action, observable, computed, toJS, makeObservable } from 'mobx'
import { fromServerToClient } from '../../../../lib/utils/collection'

class FormStore {
  @observable currentPassword = null
  @observable password = null
  @observable passwordConfirmation = null
  @observable errors = {}
  @observable isShowUpdatedCheck = false

  constructor({ transport }) {
    makeObservable(this)

    this.transport = transport
  }

  @computed
  get toForm() {
    return {
      currentPassword: this.currentPassword,
      password: this.password,
      passwordConfirmation: this.passwordConfirmation
    }
  }

  @computed
  get formErrors() {
    return toJS(this.errors)
  }

  @action('[ChangePasswordFormStore] setErrors')
  setErrors = v => {
    this.errors = v
  }

  @action('[ChangePasswordFormStore] setField')
  setField = (name, value) => {
    this[name] = value
    this.resetErrors()
  }

  @action('[UserStore] reset errors') resetErrors = () => {
    this.errors = {}
  }

  @action('[UserStore] reset field') resetField = () => {
    this.currentPassword = null
    this.password = null
    this.passwordConfirmation = null
  }

  @action('[UserStore] toggle updated check') showUpdatedCheck = (v) => {
    this.isShowUpdatedCheck = v
  }

  @action('[ChangePasswordFormStore] submit')
  submit = () => {
    const data = {
      user: this.toForm
    }

    this.transport.User.edit(data).then(
      action('[ChangePasswordFormStore] send form', resp => {
        if(resp && resp.errors) {
          this.errors = fromServerToClient(resp.errors)
          this.isShowUpdatedCheck = false
        } else {
          this.isShowUpdatedCheck = true
          this.resetField()
        }
      })
    )
  }
}

export default FormStore
