import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import Form from 'react-formal'
import cn from 'classnames'
import * as R from 'ramda'
import { FillCheckUrl } from '../../Svg'
import { editPasswordSchema } from '../../../schemas/user'
import { useStores } from '../../../shared_components/Hooks'
import { noop } from '../../../lib/utils/common'

const ChangePasswordForm = () => {
  const { store: formStore } = useStores()

  const currentPassword = useRef(null)
  const password = useRef(null)
  const passwordConfirmation = useRef(null)
  const formFields = useRef([currentPassword, password, passwordConfirmation])

  const handleSubmit = () => {
    formStore.submit()

    setTimeout(() => {
      formStore.showUpdatedCheck(false)
    }, 5000)
  }

  const hasError = fieldName => {
    return [fieldName] in formStore.formErrors
  }

  const focusFirstInvalidField = errors => {
    const fields = R.pluck('current', formFields.current)

    for (let field of fields) {
      if (field.name in errors) {
        field.focus()
        break
      }
    }
  }

  const handleChange = (value, [name]) => {
    formStore.setField(name, value[name])
  }

  const handleInvalidSubmit = errors => {
    formStore.setErrors(errors)
    focusFirstInvalidField(errors)
  }

  useEffect(() => {
    focusFirstInvalidField(formStore.formErrors)
  }, [formStore.formErrors])

  return (
    <div className='PrimaryBox'>
      <div className='PrimaryBox-title'>
        <h3 className='Text Text--a2 Text--noSpace'>Change Password</h3>

        <div className={cn('UpdatedCheck', { isShown: formStore.isShowUpdatedCheck })}>
          <div className='UpdatedCheck-content UpdatedCheck-content--static'>
            <img src={FillCheckUrl} className='UpdatedCheck-icon' alt=''/>
            <span className='Text Text--a1 Text--slateColor'>Updated</span>
          </div>
        </div>
      </div>
      <div className='PrimaryBox-content'>
        <Form
          className='Form'
          onSubmit={handleSubmit}
          schema={editPasswordSchema}
          value={formStore.toForm}
          onChange={handleChange}
          errors={formStore.formErrors}
          onError={noop}
          onInvalidSubmit={handleInvalidSubmit}
        >
          <div className='Form-row'>
            <label className='Label' htmlFor='password'>Current Password</label>
            <Form.Field
              type='password'
              id='password'
              autoComplete='current-password'
              aria-invalid={hasError('currentPassword') || null}
              aria-describedby='current-pwd-err-msg'
              className={cn('TextField TextField--defaultFont', { isInvalid: formStore.formErrors.currentPassword })}
              name='currentPassword'
              ref={currentPassword}
            />
            <Form.Message id='current-pwd-err-msg' className='ErrorText' for='currentPassword'/>
          </div>

          <div className='Form-row'>
            <label className='Label' htmlFor='new-password'>New Password</label>
            <Form.Field
              type='password'
              id='new-password'
              autoComplete='new-password'
              aria-invalid={hasError('password') || null}
              aria-describedby='new-pwd-err-msg'
              className={cn('TextField TextField--defaultFont', { isInvalid: formStore.formErrors.password })}
              name='password'
              ref={password}
            />
            <Form.Message id='new-pwd-err-msg' className='ErrorText' for='password'/>
          </div>

          <div className='Form-row'>
            <label className='Label' htmlFor='confirm-new-password'>Confirm New Password</label>
            <Form.Field
              type='password'
              id='confirm-new-password'
              autoComplete='new-password'
              aria-invalid={hasError('passwordConfirmation') || null}
              aria-describedby='confirm-pwd-err-msg'
              className={cn('TextField TextField--defaultFont',
                { isInvalid: formStore.formErrors.passwordConfirmation })
              }
              name='passwordConfirmation'
              ref={passwordConfirmation}
            />
            <Form.Message id='confirm-pwd-err-msg' className='ErrorText' for='passwordConfirmation'/>
          </div>

          <div className='Form-row'>
            <Form.Submit
              type='submit'
              className='Button Button--primary Button--withoutBorder'
              data-qa-user-change-pass-save
            >
              Save
            </Form.Submit>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default observer(ChangePasswordForm)
