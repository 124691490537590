import React from 'react'
import { Provider } from 'mobx-react'
import { withErrorBoundary } from '../../Shared/ErrorBoundary'
import { transport } from '../../../lib/services'
import FormStore from './stores/FormStore'
import ChangePasswordForm from './ChangePasswordForm'

const store = new FormStore({ transport })

const StatefulChangePasswordForm = () => (
  <Provider store={store}>
    <ChangePasswordForm />
  </Provider>
)

export default withErrorBoundary(StatefulChangePasswordForm)
