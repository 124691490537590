import { action, makeObservable, observable, runInAction, computed } from 'mobx'
import { get } from '../../../lib/utils/api'
import { serialize, debounce } from '../../../lib/utils/common'
import { fromServerToClient, isPresent } from '../../../lib/utils/collection'
import { listingCaptchaKey } from '../../../lib/config/recaptcha'

class MapStore {
  interestingPlacesGroups = []
  nearbyListingsUrl = ''
  fetchNearbyListings = debounce(this._fetchNearby, 100)
  isAllowedRequest = true

  @observable mapInfo = {}
  @observable viewOnGmapsInfo = {}
  @observable interestingPlaces = []
  @observable listingId = null
  @observable activeListingId = null
  @observable isCloseMapButtonVisible = true
  @observable markers = []
  @observable interestingPlacesFilters = {
    recreation: true,
    culture: true,
    transportation: true,
    beach: true,
    fitness: true,
    farmAndFood: true
  }

  constructor({ sessionStorage, transport }) {
    makeObservable(this)

    this.sessionStorage = sessionStorage
    this.transport = transport
  }

  @action('[MapStore] Set initial data')
  setInitialData = ({
    mapInfo,
    viewOnGmapsInfo,
    interestingPlaces,
    interestingPlacesGroups,
    listingId,
    nearbyListingsUrl
  }) => {
    this.mapInfo = mapInfo || this.mapInfo
    this.listingId = listingId
    this.viewOnGmapsInfo = viewOnGmapsInfo || this.viewOnGmapsInfo
    this.interestingPlaces = interestingPlaces || this.interestingPlaces
    this.interestingPlacesGroups = interestingPlacesGroups || this.interestingPlacesGroups
    this.nearbyListingsUrl = nearbyListingsUrl
    this.fetchInterestingPlacesFilters()
  }

  @action('[MapStore] Toggle isShownCloseButton')
  toggleCloseMapButtonVisible = v => {
    this.isCloseMapButtonVisible = v
  }

  @action('[MapStore] Toggle interestingPlacesFilters')
  toggleInterestingPlacesFilters = type => {
    this.interestingPlacesFilters[type] = !this.interestingPlacesFilters[type]
    this.saveInterestingPlacesFilters()
  }

  @action('[MapStore] Toggle isMapModalOpen')
  saveInterestingPlacesFilters = () => {
    this.sessionStorage.saveToSessionStorage(
      'interestingPlacesFilters#' + this.listingId,
      this.interestingPlacesFilters
    )
  }

  @action('[MapStore] activeListingId')
  setActiveListingId = id => {
    this.activeListingId = id
  }

  @computed
  get hasMarkers() {
    return Boolean(this.markers.length)
  }

  @computed
  get hasActiveListing() {
    return isPresent(this.activeListingId)
  }

  @computed
  get isActiveListingInCollection() {
    return this.markers.some(marker => marker.id === this.activeListingId)
  }

  fetchInterestingPlacesFilters = () => {
    const value = this.sessionStorage.fetchFromSessionStorage('interestingPlacesFilters#' + this.listingId)
    if (value) {
      this.interestingPlacesFilters = value
    }
  }

  updateSaveStatus = listing => {
    const { links: { saveAction: { url, method } } } = listing

    if (this.isAllowedRequest) {
      this.isAllowedRequest = false

      this.transport.Listing.updateSavedStatus({
        url,
        method,
        captchaKey: listingCaptchaKey(listing)
      })
        .then(response => {
          const { links, saved } = fromServerToClient(response)

          runInAction(() => {
            this.markers = this.markers.map(item => {
              if (item.id === listing.id) {
                return { ...item, links, saved }
              }
              return item
            })
          })

          return response
        })
        .then(() => {
          this.isAllowedRequest = true
        })
    }
  }

  // private
  _fetchNearby(mapBounds) {
    const path = params => `${this.nearbyListingsUrl}/?${params}`

    get(path(serialize({ mapBounds, listingId: this.listingId })))
      .then(result => {
        runInAction(() => {
          this.markers = fromServerToClient(result.collection)
        })
      })
  }
}

export default MapStore
